@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,400&display=swap");
@import "~bootstrap/scss/bootstrap";

/* -------------------common --------------------- */

$breakpoints: (
  "all": null,
  "sm": 576px,
  "md": 768px,
  "lg": 992px,
  "xl": 1200px,
);

$theme-colors: (
  "fff": #fff,
  "5d59f7": #5d59f7
);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // border: none;
  // outline: none;
  text-decoration: none;
  list-style: none;
}

body {
  background: #f9fafb;
  font-family: "Poppins", sans-serif;
  font-size: 62.5%;
}

.input-text {
  border: 1px solid #cccccc;
  padding: 5px;
  border-radius: 10px;
  min-width: 215px;
}

@each $breakpoint,
$width in $breakpoints {

  @for $i from 0 through 100 {

    @if $breakpoint =='all' {

      .border-radius-#{$i} {
        border-radius: $i * 0.063rem !important;
      }

      .fs-#{$i} {
        font-size: $i * 0.063rem !important;

        @media (max-width: 576px) {
          font-size: $i * 0.046rem !important;
        }
      }
    }

    @else {
      @media (min-width: #{$width}) {

        .border-radius-#{$breakpoint}-#{$i} {
          border-radius: $i * 0.063rem !important;
        }

        // .fs-#{$breakpoint}-#{$i} {
        //   font-size: $i * 0.063rem !important;
        // }

      }
    }
  }
}


@each $color,
$color-code in $theme-colors {
  .bg-#{$color} {
    background-color: $color-code;
  }

  .text-#{$color} {
    color: $color-code;
  }
}

.cursor-pointer {
  cursor: pointer;
}

/* ------------------- end of common --------------------- */


.mainSidebar {
  display: flex;
  width: 244px;
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  height: 100%;
  z-index: 100;

  .logo {
    margin: 17px 15px 10px 17px;
    gap: 10px;
  }

  .sidebarListItems {
    padding: 1.25rem;

    .itemList {
      margin: 29px 0px;
      color: #5d59f7;
      gap: 20px;
      margin-left: 20px;

      &:hover {
        color: #000;
      }
    }
  }
}

.mainLayout {
  margin-left: 245px;

  .mainHeader {
    height: 70px;
    background-color: #fff;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    position: sticky;
    top: 0;
    z-index: 100;

    .headerTitle {
      margin-bottom: 0;
    }

    .toggleIcon {
      display: none;
    }

    .avatar {
      display: flex;
      justify-content: space-between;
      gap: 30px;

      .avatar-badge {
        position: relative;


        svg {
          position: absolute;
          right: 0;
          bottom: 0;
          color: #fff;
          font-size: 1rem;
          padding: 1px;
          border-radius: 5px;

        }

      }

      .linkedin-profile-img {
        svg {
          background-color: #0D6EFD;

        }
      }

      .instagram-profile-img {
        svg {
          background-color: #d53f69;
          padding: 1.5px;
        }
      }

      span {
        background-color: #0d6efd;
        color: #fff;
        width: 45px;
        border-radius: 50%;
        text-align: center;
      }

      img {
        width: 45px;
        border-radius: 20%;
      }
    }
  }

  .contentWrapper {
    gap: 40px;

    h2 {
      text-align: center;
      margin-top: 40px;
    }

    .startButton {
      padding: 10px 40px;
      border: 1px solid transparent;
      background-color: #8dc63f;
      transition: all 0.1s;
      border-radius: 6px;
      color: #fff;
    }

    .card {
      box-shadow: 0 2px 10px 0 rgba(51, 51, 79, 0.02);
      border: 1px solid rgba(0, 0, 0, 0.07);
      width: 100%;
      border-radius: 9px;

      .card-body {


        .composer {
          // gap: 16px;

          .avatar {
            width: 45px;
            height: 45px;

            .linkedin-profile-img {
              border: 2px solid #0D6EFD;
            }

            .instagram-profile-img {
              margin-left: -7px;
              border: 2px solid #d53f69;
            }

            img {
              width: 45px;
              border-radius: 50%;
            }
          }

          .textBox {
            padding: 7px 10px;
            width: 100%;

            .textBoxWrite {
              padding: 0.75rem 1rem;
              border-radius: 10px;
              border: 1px solid transparent;
              background-color: #f5f5f5;
            }
          }
        }

        .shareBox {
          margin: 0.6rem 0 0 2.5rem; // padding: 0.8rem 0 0 2.5rem;

          .flex-row {
            margin-bottom: 0;
            margin-left: 1.8rem;

            .nav-item {

              padding: 0.5rem 0.9rem;

              .picBtn.nav-link.active {
                background: none !important;
                border-bottom: 2px solid #0d6efd !important;
                color: #0d6efd !important;
              }

              .nav-link {
                border-radius: 0;
              }



              span {
                margin-left: 0.9rem;
              }

              &:hover {
                background-color: #ebebeb;
                border-radius: 1rem;
              }
            }
          }

          .tab-content {
            margin-top: 40px;
            margin-bottom: 10px;

            .mainContainer {}
          }

          .previewArea {
            border: 2px solid #e6e6e6;
            border-radius: 20px;
            padding: 20px;

            .carousel-item {


              img {
                object-fit: contain;
                width: 100%;
              }

            }

            .videoPreview {
              width: 100%;
              height: auto;

              video {
                width: 600px !important;
                height: 350px !important;
                object-fit: contain !important;

              }
            }


            .btn-info {
              background-color: #5d59f7;
              padding: 0.5rem 1.7rem;
              border-radius: 2rem;
              color: #fff;
              font-weight: 500;
              border: none;
              width: 30%;
            }
          }
        }
      }

      .tab-1 {
        .uploadCard {
          border-radius: 2rem;
          padding: 2rem;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
            rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

          .btn-info {
            background-color: #5d59f7;
            padding: 0.5rem 0.7rem;
            border-radius: 2rem;
            color: #fff;
            font-weight: 500;
            border: none;
          }

          h5 {
            margin: 1.7rem 0;
          }

          svg {
            margin-right: 0.8rem;
          }
        }
      }

      textarea#comment_text {
        margin: 20px 0px;
        width: 100%;
        height: 120px;
        border: 1px solid #cccccc;
        padding: 15px;
        border-radius: 10px;
      }
    }
  }
}

.modal-dialog {
  .modal-body {
    padding: 10px 0px;

    .list-group {
      .list-group-item {
        display: flex;
        gap: 23px;
        align-items: center;

        svg {
          width: 47px;
          height: 47px;
          border-radius: 50%;
          padding: 10px;
          color: #fff;
        }
      }
    }
  }
}

.Toastify__toast-body>div:last-child {
  font-size: 14px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 4rem;
  height: 4rem;
  background-color: #0d6efd;
}

@media (min-width: 1400px) {

  .tab-content {
    .mainContainer {
      // 1320
      max-width: 1450px !important;
    }
  }
}

@media (max-width: 1200px) {
  .mainLayout .contentWrapper {
    .card {
      width: 100%;

      .card-body {
        .shareBox {
          .previewArea {
            margin-top: 30px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .mainSidebar {
    // width: 0%;
    transition: transform 0.3s ease;
    transform: translate3d(0px, 0, 0);
    display: none;
  }

  .sm-sidebar {
    display: block;
    z-index: 1000 !important;
  }

  .mainLayout {
    margin-left: 0;

    .mainHeader {
      .toggleIcon {
        display: block;
      }
    }

    .contentWrapper {
      .card {
        .card-body {
          .shareBox {
            margin: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .contentWrapper {
    img {
      width: 100%;
    }
  }
}